import React from 'react'

const ThanksLanding = (props) => (
    <section id="banner" className="style7">
        <div className="inner">

        </div>
    </section>
)

export default ThanksLanding
