import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ThanksLanding from '../components/ThanksLanding'

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Hire Devs - Message Received</title>
      <meta name="description" content="Thank You Page" />
    </Helmet>

    <ThanksLanding />

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Message Received</h1>
          </header>
          <p>Thank you for contacting Hire Devs someone will be in touch soon with a response to your project inquiry.</p>
          <p>-- Hire Devs</p>
        </div>
      </section>
    </div>

  </Layout>
)

export default Landing
